// Here you can add other styles
@import '../../node_modules/react-day-picker/lib/style';

.inputReadOnly {
	border-style: solid;
	border-color: #ffffff;
}

@mixin setBackgroundColor($color) {
	background-color: $color !important;
}

.bgColorWhite {
	@include setBackgroundColor(#ffffff);
}

div {
	.searchContainer {
		border: 1px solid #c8ced3;
		border-radius: 5px;
		padding: 8px;
	}

	.clearfix {
		overflow: auto;
	}

	.positionStatic {
		position: static;
	}
}

.btnRight {
	float: right;
	margin: 10px 0;
}

.inputGroup {
	margin: 3px 0;
	// align-items: baseline;
	.style-input-group-text {
		min-width: 128px;
		justify-content: center;
	}
	p {
		margin-left: 10px;
	}
}

.display-none {
	display: none;
}

.display-block {
	display: block;
}

.switch {
	margin-left: 1em;
	position: relative;
	top: 0.4em;
}